<template>
   <v-container 
      style="max-width: 2000px;padding-top:66px;background-color:white;height:100vh;width:100vw;overflow-y:auto;overflow-x:hidden;"
      class="bgImage"
      :style="{
         'background-image': backgroundImg?backgroundImg:'none',
         'background-color': backgroundColor?backgroundColor:'none'
         }"
      >
      <v-row class="pa-1 no-blur">
         <v-col align="center" cols="12">
            <v-card style="max-width:600px;">
               <v-card-title class="py-1 px-3"
                  style="color:#666666;font-weight:600;border-bottom: 1px solid #CCCCCC;">
                  <v-icon class="mr-2">mdi-cog</v-icon>
                  Cài đặt thông số
                  <v-spacer/>
                  <v-btn dark 
                     class="px-3 text-none"
                     color="#1565C0"
                     @click="saveAllParas">
                     <b>Áp dụng</b>
                  </v-btn>
               </v-card-title>

               <v-card-text class="px-6 py-2"
                  style="height:calc(100vh - 160px);max-height:calc(100vh - 160px);overflow-y:auto;">
                  <v-row v-for="(group, gIndex) in parasGroup" :key="gIndex">
                     <v-col cols="12" class="ma-0 mt-6 py-1 text-left"
                        style="background-color: #B2DFDB; color:black; font-size:16px; font-weight:600;">
                        {{group.name}}
                     </v-col>
                     <template v-for="(para, index) in group.paras">
                        <v-col :cols="$vuetify.breakpoint.mdAndUp?7:12"
                           style="line-height:40px;font-size:20px;text-align:left;"
                           :key="index"
                           :class="{
                              'py-1 pb-0': $vuetify.breakpoint.smAndDown,
                              'py-3': $vuetify.breakpoint.mdAndUp,
                              }">
                           <v-icon v-if="1==2"
                           class="mr-1"
                           style="font-size:28px;height:40px;width:40px;">mdi-menu-right</v-icon>
                           {{para.displayName}}
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.mdAndUp?5:12"
                           :key="1000+index"
                           :class="{
                              'py-1 pt-0 pb-4': $vuetify.breakpoint.smAndDown,
                              'py-3': $vuetify.breakpoint.mdAndUp,
                              }"
                           class="px-0 py-2">
                           <input 
                              class="px-2"
                              style="line-height:40px;width:calc(100% - 70px);border:1px solid #CCCCCC;background-color:#F2F2F2;text-align:right;font-size:22px;font-weight:600;border-radius:4px;"
                              type="text"
                              step=".01"
                              pattern="^\d+(?:\.\d{1,2})?$"
                              @focus="showKeyboard"
                              v-model="para.value">
                           <div class="pl-2"
                              style="text-align:left;font-size:20px;width:60px;line-height:40px;display:inline-block;vertical-align:top;">
                              {{para.unit}}
                           </div>
                        </v-col>
                        <v-col cols="12" class="pa-0"
                           :key="2000+index"
                           style="border-top: 1px solid #EEEEEE;">
                        </v-col>
                     </template>
                  </v-row>

               </v-card-text>
            </v-card>
         </v-col>
         <!--
         <template v-for="(widget, wIndex) in modal.widgets">
            <v-col v-if="widget.type=='new-line'"
               cols="12"
               class="pa-0"
               :style="{'min-height': widget.height?widget.height:'1px'}"
               :key="wIndex">
            </v-col>
             <v-col v-if="widget.type=='spacer'"
               :key="wIndex">
            </v-col>
            <v-col v-if="!widget.type"
               cols="6"
               :xs="widget.mobileCols||12"
               :sm="widget.mobileCols||12"
               :md="widget.laptopCols||6"
               :lg="widget.laptopCols||6"
               :xl="widget.laptopCols||6"
               :key="wIndex"
               class="pa-1"
               :style="{'max-width': widget.width&&$vuetify.breakpoint.mdAndUp?widget.width:'none'}">
               <v-card class="pa-0">
                  <v-card-title class="pa-0 pl-2 pr-2"
                     style="height:32px;font-weight:700;color:#546E7A;font-size:13px;line-height:1.7;border-bottom:1px solid #DDDDDD;">
                     {{widget.displayName}}
                     <v-spacer/>
                     <v-btn v-if="widget.control"
                        small icon 
                        @click="showSetter(widget)">
                        <v-icon style="font-size:22px;" color="#795548">mdi-hand-right</v-icon>
                     </v-btn>
                  </v-card-title>
                  <v-card-text class="pa-0"
                     style="min-height:60px;">
                     <div style="color:#2196F3;"
                        :style="{'color': widget.color||'#2196F3'}">
                        <div
                           style="font-size:26px;font-family:Verdana, sans-serif;font-weight:600;line-height:72px;display:inline-block;vertical-align:top;">
                           <v-icon style="margin:-6px -2px 0 0;font-size:30px;"
                              color="#BABABA" >
                              {{widget.icon}}</v-icon>
                           <span v-if="widget.dataType && widget.dataType.includes('BOOL')"
                              :style="{'color': widget.colors[widget.value]||'#2196F3'}"> 
                              {{widget.qos==1?widget.labels[widget.value]:'---'}}
                           </span>
                           <span v-else> 
                              {{widget.qos==1?widget.value:'---'}}
                           </span>
                           
                        </div>

                        <div v-if="widget.unit"
                           style="padding-left:2px;font-size:14px;font-weight:300;line-height:62px;display:inline-block;vertical-align:top;">
                           {{widget.unit}}
                        </div>
                     </div>
                  </v-card-text>
               </v-card>
            </v-col>
         </template>
         -->
      </v-row>
   </v-container>
</template>

<script>
import listener from "@/mixins/listener.js"
import setter from "@/mixins/setter.js"
export default {
   props: ['modal', 'customStyle'],
   mixins: [listener, setter],
   data () {
      return {
         parasGroup: [],
         backgroundImg: null,
         backgroundColor: null,
         blurBackground: true,
         socketAskKey: new Date().getTime()
      }
   },
   sockets: {
      callHTTPRequestResult: function (payload) {
         if(payload && payload.askKey == this.socketAskKey) {
            if (payload.url.includes('paras/list')) {
               this.handleApiGetReply(payload.result)
            }
            if (payload.url.includes('paras/setKeysValues')) {
               this.handleApiSaveResult(payload.result)
            }
         }
      }
   },
   methods: {
      listAllKeys () {
         if(!this.modal.parasGroup || this.modal.parasGroup.length<=0) return
         let keys = []
         this.modal.parasGroup.forEach(group => {
         if (group.paras) {
            keys = keys.concat(group.paras.map(p => p.paraName))
         }
         })
         return keys
      },
      applyValueToPara (payload) {
         this.modal.parasGroup.forEach(group => {
         if (group.paras) {
            group.paras.forEach(para => {
               para.value = -999.99
               if(payload[para.paraName]!=null && payload[para.paraName]!=undefined) {
                  para.value = Number(payload[para.paraName])
               }
            })
         }
         })
      },
      async getAllParasValue () {
         let keys = this.listAllKeys()
         if(keys.length<=0) return
         if (this.$root.isRemote) {
            this.callApiOverOTA(
                        "GET",
                        this.$root.apiGate + `paras/list?keys=${keys.join(';')}`,
                        null)
         } else {
            try {
            let res = await this.axios.get(this.$root.apiGate + `paras/list?keys=${keys.join(';')}`)
               this.handleApiGetReply(res.data)
            } catch(err) { console.log(err) }
         }
      },
      handleApiGetReply (data) {
         if (data.status=='OK') {
            this.applyValueToPara(data.content)
         } else {
            alert(data.message)
         }
      },
      handleApiSaveResult (data) {
         if (data.status=='OK') {
            alert('Lưu thành công!')
            this.getAllParasValue()
         } else {
            alert(data.message)
         }
      },
      buildPayloadToSaveData () {
         if(!this.modal.parasGroup || this.modal.parasGroup.length<=0) return null
         let payload = {}
         this.modal.parasGroup.forEach(group => {
         if (group.paras) {
            group.paras.forEach(para => {
               payload[para.paraName] = `${para.value}`
            })
         }
         })
         return payload
      },
      async saveAllParas () {
         let payload = this.buildPayloadToSaveData()
         if(!payload) return
         if (this.$root.isRemote) {
            this.callApiOverOTA(
                        "POST",
                        this.$root.apiGate + `paras/setKeysValues`,
                        { keysValues: payload })
         } else {
            try {
               let res = await this.axios.post(
                  this.$root.apiGate + `paras/setKeysValues`, 
                  { keysValues: payload })
               this.handleApiSaveResult(res.data)
            } catch(err) { alert(err) }
         }
      },
      callApiOverOTA (method, url, body) {
         this.$socket.emit('callHTTPRequest', 
         { 
            devCode: this.$root.devCode,
            askKey: this.socketAskKey,
            method: method,
            url: url,
            body: body
         })
      },
      showKeyboard (e) {
         this.$eventBus.$emit("requestShowKeyboard", e.target, 'numeric')
      }
   },
   async created () {
      if (this.modal && this.modal.parasGroup) {
         this.parasGroup = this.modal.parasGroup
      }

      if (this.customStyle) {
         if(this.customStyle.backgroundImg != undefined) this.backgroundImg = this.customStyle.backgroundImg
         if(this.customStyle.backgroundColor != undefined) this.backgroundColor = this.customStyle.backgroundColor
         if(this.customStyle.transparentColor != undefined) this.transparentColor = this.customStyle.transparentColor
         if(this.customStyle.blurBackground != undefined) this.blurBackground = this.customStyle.blurBackground
      }
      await this.getAllParasValue()
   }
}
</script>

<style>
   .bgImage {
      z-index:1;
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      background-size:cover;
   }
</style>