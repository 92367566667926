var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"bgImage",staticStyle:{"max-width":"2000px","padding-top":"66px","background-color":"white","height":"100vh","width":"100vw","overflow-y":"auto","overflow-x":"hidden"},style:({
      'background-image': _vm.backgroundImg?_vm.backgroundImg:'none',
      'background-color': _vm.backgroundColor?_vm.backgroundColor:'none'
      })},[_c('v-row',{staticClass:"pa-1 no-blur"},[_c('v-col',{attrs:{"align":"center","cols":"12"}},[_c('v-card',{staticStyle:{"max-width":"600px"}},[_c('v-card-title',{staticClass:"py-1 px-3",staticStyle:{"color":"#666666","font-weight":"600","border-bottom":"1px solid #CCCCCC"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-cog")]),_vm._v(" Cài đặt thông số "),_c('v-spacer'),_c('v-btn',{staticClass:"px-3 text-none",attrs:{"dark":"","color":"#1565C0"},on:{"click":_vm.saveAllParas}},[_c('b',[_vm._v("Áp dụng")])])],1),_c('v-card-text',{staticClass:"px-6 py-2",staticStyle:{"height":"calc(100vh - 160px)","max-height":"calc(100vh - 160px)","overflow-y":"auto"}},_vm._l((_vm.parasGroup),function(group,gIndex){return _c('v-row',{key:gIndex},[_c('v-col',{staticClass:"ma-0 mt-6 py-1 text-left",staticStyle:{"background-color":"#B2DFDB","color":"black","font-size":"16px","font-weight":"600"},attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(group.name)+" ")]),_vm._l((group.paras),function(para,index){return [_c('v-col',{key:index,class:{
                           'py-1 pb-0': _vm.$vuetify.breakpoint.smAndDown,
                           'py-3': _vm.$vuetify.breakpoint.mdAndUp,
                           },staticStyle:{"line-height":"40px","font-size":"20px","text-align":"left"},attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp?7:12}},[(1==2)?_c('v-icon',{staticClass:"mr-1",staticStyle:{"font-size":"28px","height":"40px","width":"40px"}},[_vm._v("mdi-menu-right")]):_vm._e(),_vm._v(" "+_vm._s(para.displayName)+" ")],1),_c('v-col',{key:1000+index,staticClass:"px-0 py-2",class:{
                           'py-1 pt-0 pb-4': _vm.$vuetify.breakpoint.smAndDown,
                           'py-3': _vm.$vuetify.breakpoint.mdAndUp,
                           },attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp?5:12}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(para.value),expression:"para.value"}],staticClass:"px-2",staticStyle:{"line-height":"40px","width":"calc(100% - 70px)","border":"1px solid #CCCCCC","background-color":"#F2F2F2","text-align":"right","font-size":"22px","font-weight":"600","border-radius":"4px"},attrs:{"type":"text","step":".01","pattern":"^\\d+(?:\\.\\d{1,2})?$"},domProps:{"value":(para.value)},on:{"focus":_vm.showKeyboard,"input":function($event){if($event.target.composing){ return; }_vm.$set(para, "value", $event.target.value)}}}),_c('div',{staticClass:"pl-2",staticStyle:{"text-align":"left","font-size":"20px","width":"60px","line-height":"40px","display":"inline-block","vertical-align":"top"}},[_vm._v(" "+_vm._s(para.unit)+" ")])]),_c('v-col',{key:2000+index,staticClass:"pa-0",staticStyle:{"border-top":"1px solid #EEEEEE"},attrs:{"cols":"12"}})]})],2)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }